@media screen and (max-width: 1280px) {
  .signup-h1 {
    font-size: 2.5rem !important;
  }
  .cardStyle-workspace {
    margin: 4rem auto !important;
    width: 80% !important;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 991px) {
  .signup-workspace-img {
    width: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .signup-h1 {
    font-size: 1.5rem !important;
  }
  .cardStyle-workspace {
    margin: 1rem auto !important;
    width: 90% !important;
  }
  .signup-workspace-img {
    width: 70% !important;
  }
}

@media screen and (max-width: 600px) {
}
@media screen and (max-width: 400px) {
  .signup-workspace-img {
    width: 100% !important;
  }
}
@media screen and (max-width: 350px) {
}
