@media screen and (max-width: 1280px) {
  .signup-h1 {
    font-size: 2.5rem !important;
  }
  .cardStyle1 {
    margin: 4rem auto !important;
    width: 60% !important;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
  .signup-h1 {
    font-size: 1.5rem !important;
  }
  .cardStyle1 {
    margin: 1rem auto !important;
    width: 90% !important;
  }
}

@media screen and (max-width: 600px) {
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 350px) {
}
