@media screen and (max-width: 1280px) {
  .card-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
  .work-space-card-container {
    margin: 0px !important;
    padding: 0px !important;
    display: flex !important;
    /* background-color: aquamarine !important; */
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .work-space-card-sub-container {
    /* background-color: red !important; */
    width: 100% !important;
    padding: 0px !important;
    margin: 10px 0px 0px 0px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .work-space-card-sub-container-left {
    /* background-color: green !important; */
    width: 100% !important;
    padding: 10px !important;
    margin: 0px 0px 0px 0px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .org-img {
    width: 70px !important;
    height: 70px !important;
    /* object-fit: contain !important; */
  }
  .css-1idn90j-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    width: 95% !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .org-desc {
    font-size: 14px !important;
    margin: 1rem !important;
  }
}

@media screen and (max-width: 600px) {
  .logout-Container {
    margin: 0px !important;
    display: flex !important;
    justify-content: flex-end;
    /* margin: 2rem 0rem 1rem 0rem !important; */
    margin: 1rem !important;
    font-size: 14px !important;
  }

  .img-container {
    /* display: none !important; */
    margin: 0px !important;
    /* width: 100% !important; */
  }
  .img-container img {
    width: 100% !important;
    height: 50px !important;
    object-fit: contain !important;
  }
  .card-container {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    margin: 0px !important;
    /* background-color: yellow !important; */
  }
  .heading-container {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
  }
  .heading-container h1 {
    font-size: 16px !important;
    width: 100%;
    margin: 0px !important;
  }
  .heading-container p {
    font-size: 14px !important;
    width: 100%;
    margin-top: 1rem !important;
  }
  .work-space-card-container {
    margin: 0px !important;
    padding: 0px !important;
    display: flex !important;
    /* background-color: aquamarine !important; */
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .work-space-card-sub-container {
    /* background-color: red !important; */
    width: 100% !important;
    padding: 0px !important;
    margin: 10px 0px 0px 0px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .work-space-card-sub-container-left {
    /* background-color: green !important; */
    width: 100% !important;
    padding: 10px !important;
    margin: 0px 0px 0px 0px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .org-img {
    width: 70px !important;
    height: 70px !important;
    /* object-fit: contain !important; */
  }
  .css-1idn90j-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    width: 95% !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .org-desc {
    font-size: 14px !important;
    margin: 1rem !important;
  }
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 350px) {
}
