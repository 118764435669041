@media screen and (max-width: 1280px) {
  .cardStyle-organisation {
    width: 90% !important;
  }
  .organization-img {
    height: 450px !important;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
  .organization-img {
    height: 400px !important;
  }
}

@media screen and (max-width: 600px) {
  .org-bottom-img {
    display: none !important;
  }
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 350px) {
}
