/* .slick-dots li.slick-active {
  background-color: #1976d2 !important;
  border-radius: 10px;
  opacity: 1;
  width: 20px !important;
  height: 5px !important;
} */

.slick-dots li.slick-active {
  background-color: #1976d2 !important;
  border-radius: 10px;
  opacity: 1;
  width: 20px !important;
  height: 5px !important;
}
.slick-dots li.slick-active button:before {
  color: transparent !important; /* Set the color of the dot to transparent */
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: -8px;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #000;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: -8px;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #000;
}
.carousel-card-container {
  padding: 8px;
}

/* Adjust the card margin and flex direction for different screen sizes */
@media (max-width: 1024px) {
  .carouselContainer {
    display: none !important;
  }
  .carousel-card-container {
    padding: 4px;
  }

  .product-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .product-card-media {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .carousel-card-container {
    padding: 2px;
  }

  .product-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .product-card-media {
    width: 100%;
  }
}
