.ticket-request-container {
  padding: 20px;
  max-width: 100%;
  margin: "2rem 4rem",
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
}

.filters {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.search-input, .filter-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.requests-table {
  width: 100%;
  border-collapse: collapse;
}

.requests-table th, .requests-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.requests-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.status {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.status.for-review {
  background-color: #ffc107;
  color: #000;
}

.status.completed {
  background-color: #28a745;
  color: #fff;
}

.status.in-progress {
  background-color: #007bff;
  color: #fff;
}

@media (max-width: 768px) {
  .ticket-request-container {
    padding: 5px;
  }
  
  .filters {
    gap: 3px;
  }
  
  .requests-table th, .requests-table td {
    padding: 6px;
  }
  
  .requests-table th:nth-child(4),
  .requests-table th:nth-child(5),
  .requests-table td:nth-child(4),
  .requests-table td:nth-child(5) {
    display: none;
  }
}

@media (max-width: 480px) {
  .requests-table th:nth-child(6),
  .requests-table th:nth-child(7),
  .requests-table td:nth-child(6),
  .requests-table td:nth-child(7) {
    display: none;
  }
}
