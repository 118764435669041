@media screen and (max-width: 1280px) {
  .cardStyle {
    margin: 4rem auto !important;
  }
  .header-img-forget-container {
    margin: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .header-img-forget {
    width: 90% !important;
  }
  .heading-container {
    margin-top: 0rem !important;
  }
  .heading-container h1 {
    margin: 0.5rem !important;
    font-size: 2.5rem !important;
  }
  .input-field-container {
    margin-top: 2rem !important;
  }
}
@media screen and (max-width: 1024px) {
  .heading-container h1 {
    margin: 0.5rem !important;
    font-size: 2rem !important;
  }
}
@media screen and (max-width: 991px) {
  .heading-container h1 {
    margin: 0.5rem !important;
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .header-img-forget {
    display: block !important;
    width: 70% !important;
  }
  .cardStyle {
    width: 70% !important;
  }
}

@media screen and (max-width: 600px) {
  .header-img-forget {
    display: block !important;
  }
  .cardStyle {
    width: 80% !important;
  }
  .heading-container h1 {
    margin: 0.5rem !important;
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 400px) {
  .header-img-forget {
    display: block !important;
    width: 90% !important;
  }
  .cardStyle {
    width: 90% !important;
  }
  .heading-container h1 {
    margin: 0.5rem !important;
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 350px) {
  .cardStyle {
    width: 90% !important;
  }
}
