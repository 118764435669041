.bottomBar {
  background-color: #232f3e;
  height: 4.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0rem 4rem;
  overflow: hidden;
}

.listItems {
  margin: 0px;
  cursor: pointer;
  white-space: nowrap;
}

.iconAlign {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  white-space: nowrap;
}

.scrollableContainer {
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 2rem;
  white-space: nowrap;
}

/* Custom scrollbar styles */
.scrollableContainer::-webkit-scrollbar {
  height: 4px;
}

.scrollableContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

.scrollableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.scrollableContainer::-webkit-scrollbar-track {
  background: #232f3e;
}
@media screen and (max-width: 1440px) {
  .app-bar-container {
  }
  .img-container {
  }
  .searchbar {
    width: 58% !important;
  }
  .scrollableContainer {
    display: flex !important;
    align-items: center !important;
    overflow-x: auto !important;
    gap: 2rem !important;
  }
}
@media screen and (max-width: 1280px) {
  .searchbar {
    width: 50% !important;
  }
}
@media screen and (max-width: 1024px) {
  .searchbar {
    width: 40% !important;
  }
}
@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 600px) {
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 350px) {
}
