.title-heading {
}
.main-container {
}
.subscription-container {
}
/* Media query for screens with a maximum width of 600px */
@media screen and (max-width: 600px) {
  .main-container {
    margin: 1rem 1rem 0rem 2rem !important;
    font-size: 0.875rem !important;
  }
  .title-heading {
    font-size: 20px !important;
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
  }
  .dashboard-section-card {
    /* background-color: red !important; */
    /* padding: 0px !important; */
    margin-left: -14px !important;
    margin-top: -10px !important;
  }
  .subscription-container {
    /* display: flex !important;
    flex-direction: column !important;
    background-color: green !important;
    justify-content: center !important;
    align-items: flex-start !important; */
    margin-left: -14px !important;
    margin-top: -10px !important;
  }
  .subscription-sub-container {
    display: flex !important;
    flex-direction: row !important;
    /* background-color: red !important; */
    justify-content: space-between;
    align-items: center !important;
  }
  .manage-sub-btn {
    margin-top: 20px !important;
  }
  .team-container {
    margin-top: -4px !important;
    margin-bottom: 20px !important;
  }
  .footer-container {
    display: none !important;
  }
  .card-title {
    font-size: 1.25rem !important;
    margin-top: 4px !important;
  }
  .card-desc {
    margin: 0px !important;
    /* line-height: 1.5rem !important; */
  }

  .space {
    height: 50px !important;
    margin-top: 20px !important;
    padding: 10px !important;
  }
  .shop-text {
    display: none !important;
  }
  .sub-button {
    display: flex !important;
    align-self: flex-start !important;
    margin-right: 100px !important;
    padding: 4px !important;
  }
  .sub-btn-container {
    display: flex !important;
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 1024px) {
  .main-container {
    margin: 1rem 1rem 0rem 2rem !important;
    font-size: 0.875rem !important;
  }
  .title-heading {
    font-size: 20px !important;
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
  }
  .dashboard-section-card {
    /* background-color: red !important; */
    /* padding: 0px !important; */
    margin-left: -14px !important;
    margin-top: -10px !important;
  }
  .subscription-container {
    /* display: flex !important;
    flex-direction: column !important;
    background-color: green !important;
    justify-content: center !important;
    align-items: flex-start !important; */
    margin-left: -14px !important;
    margin-top: -10px !important;
  }
  .subscription-sub-container {
    display: flex !important;
    flex-direction: row !important;
    /* background-color: red !important; */
    justify-content: space-between;
    align-items: center !important;
  }
  .manage-sub-btn {
    margin-top: 20px !important;
  }
  .team-container {
    margin-top: -4px !important;
    margin-bottom: 20px !important;
  }
  .footer-container {
    display: none !important;
  }
  .card-title {
    font-size: 1.25rem !important;
    margin-top: 4px !important;
  }
  .card-desc {
    margin: 0px !important;
    /* line-height: 1.5rem !important; */
  }

  .space {
    height: 50px !important;
    margin-top: 20px !important;
    padding: 10px !important;
  }
  .shop-text {
    display: none !important;
  }
  .sub-button {
    display: flex !important;
    align-self: flex-start !important;
    margin-right: 100px !important;
    padding: 4px !important;
  }
  .sub-btn-container {
    display: flex !important;
    justify-content: flex-start !important;
  }
}
