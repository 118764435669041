@media (max-width: 1440px) {
  .checkout-btn {
    right: 4% !important;
  }
}
@media (max-width: 1280px) {
  .checkout-btn {
    right: 4% !important;
  }
}
@media (max-width: 1024px) {
  .headAlign-cart {
    margin: 0px 1px !important;
  }
  .rowAlign-cart {
    margin: 4px 1px !important;
  }
  .checkout-btn {
    right: 4% !important;
  }
  .billing-text {
    font-size: small !important;
    padding: 8px !important;
  }
}
@media (max-width: 991px) {
  .rowAlign-cart {
    margin: 1px 1px !important;
    font-size: small !important;
  }
}
